<template>
    <div id="template" class="relative sm:w-full md:w-full lg:w-full mx-auto self-center">
        <div class="header flex">
            <div class="title flex items-center uppercase">Data story</div>
            <div class="save flex items-center justify-center ml-auto" @click="tambah" v-if="show">
                <feather-icon icon="PlusIcon"></feather-icon>
            </div>
        </div>
        <div class="body sm:w-full md:w-full lg:w-full mx-auto self-center">
            <vs-table @selected="handleSelected" pagination search max-items="10" :data="data">
                <template slot="thead">
                    <vs-th>No</vs-th>
                    <vs-th sort-key="section_story_title">Story Title</vs-th>
                    <vs-th sort-key="section_story_description">Story Description</vs-th>
                    <vs-th sort-key="meaning_story_title">Meaning Title</vs-th>
                    <vs-th sort-key="meaning_story_description">Meaning Description</vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                            {{indextr+1}}
                        </vs-td>

                        <vs-td :data="data[indextr].section_story_title">
                            {{data[indextr].section_story_title}}
                        </vs-td>

                        <vs-td :data="data[indextr].section_story_description">
                            <span v-html="data[indextr].section_story_description"/>
                        </vs-td>

                        <vs-td :data="data[indextr].section_meaning_title">
                            {{data[indextr].section_meaning_title}}
                        </vs-td>

                        <vs-td :data="data[indextr].section_meaning_description">
                            <span v-html="data[indextr].section_meaning_description"/>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                data: [],
                show: true,
            }
        },
        mounted() {
            this.loadData()
        },
        methods: {
            handleSelected($id) {
                if ($id.id) {
                    this.$router.push('/detail-story/' + $id.id)
                }
            },
            async loadData() {
                this.$vs.loading()
                await this.getData().then(resp => {
                    this.data = resp
                    if(resp.length > 0) {
                        this.show = false;
                    }
                })
                this.$vs.loading.close()
            },
            getData() {
                return new Promise((resolve, reject) => {
                    this.$http({
                            url: 'v1/id/story',
                            method: 'GET',
                        })
                        .then(response => {
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            reject(error)
                        })
                })
            },
            tambah() {
                this.$router.push('/add-story')
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/vuesax/_variables.scss";

    #template {
        min-height: 400px;
        background-color: #ffffff;
        box-shadow: 0 0px 20px 0 rgba(0, 0, 0, .05);
        border-radius: .5rem;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        .header {
            padding-top: 20px;
            margin-bottom: 40px;

            .help-toggle {
                background: rgba(var(--vs-primary), 1) !important !important;

                span {
                    font-size: 16px;
                    color: rgba(var(--vs-primary), 1) !important !important;
                }
            }

            .back {
                width: 40px;
                height: 40px;
                background: rgba(0, 0, 0, .05);
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .title {
                color: #444444;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 1;
            }

            .save {
                width: 40px;
                height: 40px;
                background: rgba(var(--vs-primary), 1) !important;
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    color: $white;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .body {
            .img-thumbnail {
                width: 50px;
            }

            .icons {
                width: 100%;

                li {
                    width: 40px;
                    height: 40px;
                    border-radius: 3px;
                    border: 1px solid transparent;
                    transition: all .5s;
                    cursor: pointer;

                    .feather-icon {
                        width: 20px;
                        height: 20px;
                    }

                    &:hover {
                        background: rgb(255, 255, 255);
                        border: 1px solid rgb(248, 249, 250);
                        box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .05);
                    }

                    &.active {
                        background: rgba(var(--vs-primary), 1) !important;

                        .feather-icon {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
</style>